// src/pages/Legal.jsx
import React from "react";
import { Sheet, Typography, Divider, Link, Container } from "@mui/joy";

export default function Legal() {

  return (
    <Sheet
      sx={{
        minHeight: "100vh", // ✅ Ensures background covers entire viewport
        backgroundColor: "background.surface", // ✅ Uses theme background
        color: "text.primary", // ✅ Ensures text color adapts to theme
        display: "flex",
        flexDirection: "column", // ✅ Fixes spacing issues
        alignItems: "center",
        justifyContent: "flex-start", // ✅ Prevents extra space at the top
        py: 6,
      }}
    >
    <Container maxWidth="md">
      <Typography level="h2" sx={{ fontWeight: "bold", mb: 2, color: "primary.main" }}>
      License
      </Typography>
      <Divider sx={{ my: 2, opacity: 0.6 }} />
      
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
      ALL RIGHTS RESERVED
      </Typography>
      <Typography level="body-md" sx={{ mb: 2, color: "text.secondary" }}>
        Last modified: <strong>March 13th, 2025</strong>
      </Typography>
      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        All rights reserved. This website and applications or any portion thereof may not be
        reproduced or used in any manner whatsoever without the express written permission of
        the owner except for the use of brief quotations in a review.
      </Typography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        Contact Us
      </Typography>
      <Typography level="body-md" sx={{ opacity: 0.9, color: "text.secondary" }}>
        If you have any questions, feel free to reach us at:
      </Typography>
      <Link href="mailto:hello@kangostudios.com" sx={{ fontWeight: "bold", fontSize: "1.1rem", mt: 1, display: "block", color: "primary.main" }}>
        hello@kangostudios.com
      </Link>
      <Typography level="body-md" sx={{ mt: 1 }}>
        <Link href="https://kangostudios.com" target="_blank" rel="noopener" sx={{ fontWeight: "bold", color: "primary.main" }}>
          kangostudios.com
        </Link>
      </Typography>
    </Container>
</Sheet>
  );
}
