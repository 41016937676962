// src/pages/Privacy.jsx
import React from "react";
import { Sheet, Container, Typography, Divider, Link } from "@mui/joy";
import MarkdownTypography from "../components/MarkdownTypography";

export default function Privacy() {    
  return (
        <Sheet
        sx={{
          minHeight: "100vh", // ✅ Ensures background covers entire viewport
          backgroundColor: "background.surface", // ✅ Uses theme background
          color: "text.primary", // ✅ Ensures text color adapts to theme
          display: "flex",
          flexDirection: "column", // ✅ Fixes spacing issues
          alignItems: "center",
          justifyContent: "flex-start", // ✅ Prevents extra space at the top
          py: 6,
        }}
      >
    <Container maxWidth="md">
      <Typography level="h2" sx={{ fontWeight: "bold", mb: 2, color: "primary.main" }}>
        Privacy Policy
      </Typography>
      <Typography level="h3" sx={{ fontWeight: "bold", mb: 2, color: "text.primary" }}>
        A BASIC HUMAN RIGHT
      </Typography>
      <Typography level="body-md" sx={{ mb: 2, color: "text.secondary" }}>
        Last modified: <strong>March 13th, 2025</strong>
      </Typography>
      <Divider sx={{ my: 2, opacity: 0.6 }} />

      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        Your Privacy Matters
      </Typography>
      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        Your privacy is very important to Kango Studios. This Privacy Policy explains how we 
        collect, use, disclose, transfer, and store your information. Please take a moment to 
        review our practices, and contact us if you have any questions.
      </Typography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        Summary
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        We take privacy very seriously and never share user data with any third party. By default, 
        our apps track crash reports and analytics (anonymously) to help us improve the apps. This 
        information helps developers understand crashes, identify issues, and analyze which 
        features are being used most to make better development decisions. 
        If you do not wish for us to receive crash reports and analytics, you can opt out under:
        \n\n
        **Settings → Privacy.**
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        What We Track
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        By default, we track crash reports. When your app crashes, we receive a report explaining 
        why it happened so we can fix it. We also track if you have installed our app and what 
        features you use the most. This helps us create new features that matter most to you.
        If you prefer not to send crash reports and analytics, you can opt out in:
        \n\n
        **Settings → Privacy.**
      </MarkdownTypography>
      <MarkdownTypography level="body-sm" sx={{ textAlign: "justify", opacity: 0.7, mt: 1 }}>
        **Note:** Opting out may affect how long it takes us to fix a specific 
        crash or bug.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        What We Don't Track
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        We do not track your personal data. Anything you enter into our app **stays on Apple's 
        iCloud service**—there is no way for us to access it. We strongly believe in **on-device 
        processing** for better security. Since your data is stored only on your device and 
        Apple's iCloud, there are fewer security risks, and we do not have to manage it. 
        **Everybody wins (except advertisers 😜)!**
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        What That Means for You
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        No personal data is stored with us. Everything is **on your phone or Apple's iCloud 
        service**. You are in control of your personal data, as it should be. All of your 
        information never leaves Apple's servers, and **it never will**.
        We do not sell your data (**we don't even have it!**) and never will. You can use our 
        app with **complete peace of mind** knowing your data belongs to you.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        Privacy Questions
      </Typography>
      <Typography level="body-md" sx={{ opacity: 0.9 }}>
        If you have any questions about our Privacy Policy or data processing, or if you wish to 
        report a possible breach of privacy laws, please contact us:
      </Typography>
      <Link href="mailto:hello@kangostudios.com" sx={{ fontWeight: "bold", fontSize: "1.1rem", mt: 1, display: "block" }}>
        hello@kangostudios.com
      </Link>
      <Typography level="body-md" sx={{ mt: 1 }}>
        <Link href="https://kangostudios.com" target="_blank" rel="noopener" sx={{ fontWeight: "bold" }}>
          kangostudios.com
        </Link>
      </Typography>

      <Typography level="body-sm" sx={{ opacity: 0.7, mt: 3, textAlign: "justify" }}>
        Our Privacy Policy may change from time to time. Any changes will be posted on this page.
      </Typography>
    </Container>
    </Sheet>
  );
}
