import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useColorScheme } from "@mui/joy";
import { Drawer, Box, IconButton, Divider, List, ListItem, ListItemButton, ListItemDecorator, ListItemContent } from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { menuItems } from "./MenuItems"; // ✅ Import shared menu items

// Dynamically import icons
const icons = {
  HomeRoundedIcon: require("@mui/icons-material/HomeRounded").default,
  DashboardRoundedIcon: require("@mui/icons-material/DashboardRounded").default,
  SettingsRoundedIcon: require("@mui/icons-material/SettingsRounded").default,
  EmailIcon: require("@mui/icons-material/Email").default,
};

export default function Sidebar({ mobileOpen, onClose, theme, toggleTheme }) {
  return (
    <>
      {/* Sidebar for mobile (animates in from the left) */}
      <Drawer
      open={mobileOpen}
      onClose={onClose}
      anchor="left" // ✅ Matches right-side placement
      variant="plain"
      slotProps={{
        backdrop: {
          sx: { background: "rgba(0, 0, 0, 0.5)", backdropFilter: "none" }, // ✅ Removes blur
        },
        content: {
          sx: {
            width: 240, // Sidebar width
            height: "100%",
            backgroundColor: "background.surface", // ✅ Uses MUI's theme-based background
            color: "text.primary", // ✅ Ensures text adapts to light/dark mode
            borderLeft: "1px solid var(--joy-palette-divider)", // ✅ Uses MUI's divider color
            borderRadius: "0px 12px 12px 0px", // ✅ Rounds only the top-right & bottom-right corners
            overflow: "hidden", // ✅ Ensures content respects the rounded corners
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 6px -1px, rgba(0, 0, 0, 0.14) 0px 2px 4px -1px", // ✅ Optional shadow for better UI
            transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1)", // ✅ Smooth opening transition
          },
        },
      }}
      sx={{
        width: 240, // Slightly wider for better layout
        height: "100%",
        position: "fixed",
        top: 0,
        right: 0,
        backgroundColor: "background.surface", // ✅ Uses MUI's theme-based background
        color: "text.primary", // ✅ Ensures text adapts to light/dark mode
        borderLeft: "1px solid var(--joy-palette-divider)", // ✅ Uses MUI's divider color
        borderRadius: "0 12px 12px 0", // ✅ Left-side aligned with rounded right corners
        transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1)", // ✅ Matches smooth opening transition
      }}
      >
        <SidebarContent onClose={onClose} theme={theme} toggleTheme={toggleTheme} />
        </Drawer>
    </>
  );
}

/* Sidebar Content (Used in Mobile) */
function SidebarContent({ onClose, theme, toggleTheme }) {
  const { mode, setMode } = useColorScheme(); // Hook for MUI Joy theme
  const location = useLocation(); // ✅ Get current page path

  return (
    <>
      {/* Sidebar Header */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 2 }}>
      <IconButton onClick={() => setMode(mode === "light" ? "dark" : "light")}>
      {mode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
      </IconButton>

        {onClose && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      <Divider sx={{ mb: 2, opacity: 0.6 }} />

      {/* Sidebar Navigation */}
      <List aria-labelledby="nav-list-browse" sx={{ px: 2 }}>
        {menuItems.map((item) => {
          const IconComponent = icons[item.icon];

          return (
            <ListItem key={item.label} nested sx={{ mb: 1 }}>
              <ListItemButton
                component={Link}
                to={item.path}
                onClick={onClose}
                selected={location.pathname === item.path} // ✅ Mark as selected if it's the current page
                sx={{
                  borderRadius: "6px",
                  "&:hover": { backgroundColor: "neutral.700" },
                  "&.Mui-selected": { backgroundColor: "#05A9F4", color: "#fff" },
                }}
              >
                {/* Icon */}
                <ListItemDecorator>
                  <IconComponent />
                </ListItemDecorator>

                {/* Label */}
                <ListItemContent>{item.label}</ListItemContent>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
