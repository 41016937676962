import React from "react";
import { Link } from "react-router-dom";
import {
    Sheet,
    Typography,
    Button,
    Container,
    Grid,
    Card,
    CardContent,
    Box
  } from "@mui/joy";

  function HeroSection() {
    return (
      <Box
        sx={{
          minHeight: "80vh", // Ensures a large hero section
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          backgroundColor: "background.surface", // Adapts to theme
          color: "text.primary",
          px: 3, // Padding for responsiveness
        }}
      >
        <Container>
          {/* Hero Text Content */}
          <Typography level="h1" sx={{ fontWeight: "bold", fontSize: "3rem", mb: 2 }}>
            Innovative iOS Apps. <br /> Crafted for Excellence.
          </Typography>
          <Typography level="body-lg" sx={{ mb: 4, maxWidth: "600px", mx: "auto", opacity: 0.8 }}>
            At <strong>Kango Studios</strong>, we build powerful, user-friendly iOS apps that enhance productivity, 
            simplify life, and deliver seamless experiences. From smart finance tracking to intuitive workout logs, 
            our apps are designed with innovation and precision.
          </Typography>
  
          {/* Call-to-Action Button */}
          <Link to="/portfolio">
          <Button
            sx={{
              px: 4,
              py: 1.5,
              fontSize: "1.2rem",
              fontWeight: "bold",
              backgroundColor: "primary.main",
              color: "white",
              borderRadius: "99px", // ✅ Pill shape
              textTransform: "none",
              boxShadow: "lg", // Adds slight shadow
              "&:hover": { backgroundColor: "primary.dark" },
            }}
          >
            Explore Our Apps
          </Button>
          </Link>
        </Container>
      </Box>
    );
  }

function LatestWork() {
    const projects = [
      {
        name: "Subscription Manager",
        description: "Keep track of your subscriptions and services the easy way!",
        image: `${process.env.PUBLIC_URL}/assets/subscription-manager-preview.png`,
        link: "https://itunes.apple.com/us/app/subscription-manager-bills/id1259029889?mt=8"
      },
      {
        name: "Grade Control",
        description: "Track grades, GPA, and know what grades you need!",
        image: `${process.env.PUBLIC_URL}/assets/grade-control-preview.png`,
        link: "https://itunes.apple.com/us/app/grade-control-school-grades/id1123787414?mt=8"
      },
      {
        name: "Flow",
        description: "Budget the easy way! Track account balances, not transactions!",
        image: `${process.env.PUBLIC_URL}/assets/flow-preview.png`,
        link: "https://itunes.apple.com/us/app/loot-track-your-money/id1447322358?mt=8"
      },
      {
        name: "Lifting Log",
        description: "Track workouts, exercises, measurements, and more...",
        image: `${process.env.PUBLIC_URL}/assets/lifting-log-preview.png`,
        link: "https://apps.apple.com/us/app/id1486426330"
      }
    ];
  
    return (
        <Sheet
        sx={{
          textAlign: "center",
          py: { xs: 6, sm: 10 },
          backgroundColor: "background.level1", // ✅ Theme-adaptive background color
          overflow: "hidden",
        }}
      >
      <Container>
        <Typography level="h3" align="center" gutterBottom sx={{ color: "text.primary", mb: 4 }}>
          Latest Work
        </Typography>
        <Grid container spacing={2}>
          {projects.map((project) => (
            <Grid item xs={12} sm={6} md={3} key={project.name}>
              <Card variant="outlined" sx={{ textAlign: "center", overflow: "hidden", alignItems: "center" }}>
                <img
                  src={project.image}
                  alt={project.name}
                  style={{ width: "150px", height: "150px", objectFit: "cover" }}
                />
                <CardContent>
                  <Typography level="h5">{project.name}</Typography>
                  <Typography level="body-sm" sx={{ mt: 1, color: "text.secondary" }}>
                    {project.description}
                  </Typography>
                  {project.link && (
                    <Button component="a" href={project.link} variant="soft" sx={{ mt: 2 }}>
                      App Store
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      </Sheet>
    );
}

function OurMission() {
    return (
      <Sheet
        sx={{
          textAlign: "center",
          py: { xs: 6, sm: 10 },
          backgroundColor: "background.level2", // ✅ Theme-adaptive background color
          overflow: "hidden",
        }}
      >
        <Container>
          <Typography level="h3" gutterBottom sx={{ fontWeight: "bold", color: "text.primary", mb: 4 }}>
            Our Mission
          </Typography>
          <Typography level="body-lg" sx={{ maxWidth: 700, mx: "auto", color: "text.secondary" }}>
            At Kango Studios, we're building the next generation of Apple Apps through design, privacy, and accessibility.
          </Typography>
  
          {/* Core Values */}
          <Grid container spacing={2} sx={{ mt: 3 }}>
            {[
              { title: "Innovation", desc: "We push the boundaries of technology." },
              { title: "Excellence", desc: "We deliver top-tier quality in every project." },
              { title: "User Focus", desc: "Designing with the user in mind at all times." },
              { title: "Reliability", desc: "Delivering dependable and high-performing applications." },
            ].map((item) => (
              <Grid item xs={12} sm={6} md={3} key={item.title}>
                <Card variant="outlined" sx={{ textAlign: "center", py: 2 }}>
                  <CardContent>
                    <Typography level="h5" sx={{ fontWeight: "bold" }}>
                      {item.title}
                    </Typography>
                    <Typography level="body-sm" sx={{ mt: 1, color: "text.secondary" }}>
                      {item.desc}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* Spacing before the quote */}
        <Container sx={{ mt: { xs: 6, sm: 10 } }}> 
          <Typography
            level="h5"
            sx={{
              fontStyle: "italic",
              opacity: 0.9,
              color: "text.secondary",
              mb: 2,
            }}
          >
            "Simple can be harder than complex: You have to work hard to get your thinking clean to make it simple. But it’s worth it in the end because once you get there, you can move mountains."
          </Typography>
          <Typography level="body-sm" sx={{ fontWeight: "bold", color: "text.primary" }}>
            — Steve Jobs
          </Typography>
        </Container>
      </Sheet>
    );
}

export default function Home() {
    return (
        <>
        <HeroSection />
        <LatestWork />
        <OurMission />
        </>
    )
}
