// src/pages/About.jsx
import React from "react";
import { Link } from "react-router-dom";
import { Typography, Box, Button, Container, Grid, Card, CardContent } from "@mui/joy";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone"; // iOS Apps Icon
import ShieldIcon from "@mui/icons-material/Shield"; // Privacy Icon
import VisibilityIcon from "@mui/icons-material/Visibility"; // Accessibility Icon

export default function About() {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: "background.surface",
          color: "text.primary",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          textAlign: "center",
          px: 3,
          py: 6,
        }}
      >
        <Container>
          <Typography level="h1" sx={{ fontWeight: "bold", fontSize: "2.8rem", mb: 2 }}>
            Redefining Indie Development Since 2014
          </Typography>
          <Typography level="body-lg" sx={{ mb: 4, maxWidth: "750px", mx: "auto", opacity: 0.85 }}>
            Kango Studios was officially founded in 2018, but the journey started much earlier. Since 2014, 
            I have been developing high-quality, cutting-edge iOS apps, bringing a deep expertise in 
            software engineering, UI/UX design, marketing, and DevOps. Our apps are built using Swift, 
            following Apple’s best practices in design, privacy, and accessibility—serving thousands of users every day!
          </Typography>
  
          <Typography level="h2" sx={{ fontWeight: "bold", fontSize: "2rem", mb: 3 }}>
            Our Mission
          </Typography>
          <Typography level="body-lg" sx={{ mb: 4, maxWidth: "750px", mx: "auto", opacity: 0.85 }}>
            We’re building the next generation of Apple apps, focused on design, privacy, and accessibility.
          </Typography>
  
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Card variant="outlined" sx={{ textAlign: "center", p: 3, boxShadow: "sm" }}>
                <PhoneIphoneIcon sx={{ fontSize: 50, color: "primary.main", mb: 2 }} />
                <CardContent>
                  <Typography level="h5" fontWeight="bold">Design Excellence</Typography>
                  <Typography level="body-sm" sx={{ mt: 1, opacity: 0.75 }}>
                    We believe design is the backbone of a great app. By following Apple’s Human Interface Guidelines, 
                    we ensure a seamless, beautiful, and intuitive experience for every user.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
  
            <Grid item xs={12} sm={4}>
              <Card variant="outlined" sx={{ textAlign: "center", p: 3, boxShadow: "sm" }}>
                <ShieldIcon sx={{ fontSize: 50, color: "primary.main", mb: 2 }} />
                <CardContent>
                  <Typography level="h5" fontWeight="bold">Privacy First</Typography>
                  <Typography level="body-sm" sx={{ mt: 1, opacity: 0.75 }}>
                    Privacy is a fundamental human right. We minimize data collection, keep it anonymous, 
                    and never sell your data—your trust is our top priority.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
  
            <Grid item xs={12} sm={4}>
              <Card variant="outlined" sx={{ textAlign: "center", p: 3, boxShadow: "sm" }}>
                <VisibilityIcon sx={{ fontSize: 50, color: "primary.main", mb: 2 }} />
                <CardContent>
                  <Typography level="h5" fontWeight="bold">Accessibility for All</Typography>
                  <Typography level="body-sm" sx={{ mt: 1, opacity: 0.75 }}>
                    We design apps to be accessible to everyone, ensuring users of all abilities 
                    can fully experience and enjoy our products.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
  
          <Link to="/portfolio">
            <Button
              sx={{
                mt: 5,
                px: 4,
                py: 1.5,
                fontSize: "1.2rem",
                fontWeight: "bold",
                backgroundColor: "primary.main",
                color: "white",
                borderRadius: "99px",
                textTransform: "none",
                boxShadow: "lg",
                "&:hover": { backgroundColor: "primary.dark" },
              }}
            >
              Explore Our Apps
            </Button>
          </Link>
        </Container>
      </Box>
    );
  }