// src/components/menuItems.js
export const menuItems = [
  { label: "Home", icon: "HomeRoundedIcon", path: "/" },
  { label: "Portfolio", icon: "SettingsRoundedIcon", path: "/portfolio" },
  { label: "About", icon: "DashboardRoundedIcon", path: "/about" },
  { label: "Contact", icon: "EmailIcon", path: "mailto:hello@kangostudios.com" }
];

export const menuItemsLegal = [
  { label: "Privacy", icon: "SettingsRoundedIcon", path: "/privacy" },
  { label: "Terms", icon: "SettingsRoundedIcon", path: "/terms" },
  { label: "Legal", icon: "SettingsRoundedIcon", path: "/legal" }
];
