import React from "react";
import { Link } from "react-router-dom";
import {
  Sheet,
  Typography,
  IconButton,
  Stack,
  Container,
  Grid,
  Divider,
} from "@mui/joy";
import AppleIcon from "@mui/icons-material/Apple";
import EmailIcon from "@mui/icons-material/Email";
import FavoriteIcon from "@mui/icons-material/Favorite";

const quickLinks = [
  { label: "Home", path: "/" },
  { label: "About", path: "/about" },
  { label: "Portfolio", path: "/portfolio" },
];

const legalLinks = [
  { label: "Privacy Policy", path: "/privacy" },
  { label: "Terms & Conditions", path: "/terms" },
  { label: "Legal", path: "/legal"}
];

export default function Footer() {
  return (
    <Sheet
      component="footer"
      invertedColors
      sx={{
        py: 4,
        backgroundColor: "neutral.800", // ✅ Darker Background
        color: "neutral.200",
      }}
    >
      <Container>
        <Grid container spacing={4}>
          {/* Logo Section */}
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          >
           {/* Logo + Company Name */}
           <Link to="/" style={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
             <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" style={{ height: "50px" }} />
             <Typography level="h4" sx={{ fontWeight: "bold", color: "white", marginLeft: 1 }}>
               Kango Studios
             </Typography>
           </Link>
          </Grid>

{/* Navigation Links */}
<Grid
  item
  xs={12}
  sm={4}
  sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  }}
>

  <Grid container spacing={2} justifyContent="center">
    {/* First Column */}
    <Grid item>
      <Stack component="nav" spacing={1}>
      {quickLinks.map(({ label, path }) => (
          <Link
            key={label}
            to={path}
            style={{
              textDecoration: "none",
              color: "inherit",
              fontWeight: 500,
              fontSize: "1rem",
              padding: "8px 16px",
              borderRadius: "8px",
              transition: "all 0.3s ease",
              display: "inline-block",
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "rgba(255, 255, 255, 0.1)";
              e.target.style.color = "#fff";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "transparent";
              e.target.style.color = "inherit";
            }}
          >
            {label}
          </Link>
        ))}
      </Stack>
    </Grid>

    {/* Second Column */}
    <Grid item>
      <Stack component="nav" spacing={1}>
      {legalLinks.map(({ label, path }) => (
          <Link
            key={label}
            to={path}
            style={{
              textDecoration: "none",
              color: "inherit",
              fontWeight: 500,
              fontSize: "1rem",
              padding: "8px 16px",
              borderRadius: "8px",
              transition: "all 0.3s ease",
              display: "inline-block",
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "rgba(255, 255, 255, 0.1)";
              e.target.style.color = "#fff";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "transparent";
              e.target.style.color = "inherit";
            }}
          >
            {label}
          </Link>
        ))}
      </Stack>
    </Grid>
  </Grid>
</Grid>


          {/* Contact Information */}
          <Grid item xs={12} sm={4} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              {/* Email Button */}
              <IconButton
                component="a"
                href="mailto:hello@kangostudios.com"
                sx={{
                  backgroundColor: "neutral.700",
                  borderRadius: "6px", // ✅ Small square
                  width: 40,
                  height: 40,
                  "&:hover": { backgroundColor: "neutral.500" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <EmailIcon sx={{ fontSize: 24, color: "white" }} />
              </IconButton>

              {/* Apple Logo Button */}
              <IconButton
                component="a"
                href="https://itunes.apple.com/us/developer/kango-studios-llc/id1115907089?mt=8"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  backgroundColor: "neutral.700",
                  borderRadius: "6px", // ✅ Small square
                  width: 40,
                  height: 40,
                  "&:hover": { backgroundColor: "neutral.500" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AppleIcon sx={{ fontSize: 24, color: "white" }} />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>

        {/* Divider for Separation */}
        <Divider sx={{ my: 3, borderColor: "neutral.200" }} />

        {/* Copyright Notice */}
        <Typography level="body-sm" align="center" color="secondary" sx={{ opacity: 0.7, display: "flex", alignItems: "center", justifyContent: "center" }}>
  © {new Date().getFullYear()} Kango Studios. All rights reserved. Made with love 
  <FavoriteIcon sx={{ fontSize: 16, color: "#05A9F4", mx: 0.5 }} />
</Typography>
      </Container>
    </Sheet>
  );
}
