import React from "react";
import { Sheet, Container, Typography, Divider, Link } from "@mui/joy";
import MarkdownTypography from "../components/MarkdownTypography";

export default function Terms() {    
  return (
    <Sheet
    sx={{
      minHeight: "100vh", // ✅ Ensures background covers entire viewport
      backgroundColor: "background.surface", // ✅ Uses theme background
      color: "text.primary", // ✅ Ensures text color adapts to theme
      display: "flex",
      flexDirection: "column", // ✅ Fixes spacing issues
      alignItems: "center",
      justifyContent: "flex-start", // ✅ Prevents extra space at the top
      py: 6,
    }}
  >
    <Container maxWidth="md">
      <Typography level="h2" sx={{ fontWeight: "bold", mb: 2, color: "primary.main" }}>
        Terms of Service
      </Typography>
      <Typography level="h3" sx={{ fontWeight: "bold", mb: 2, color: "text.primary" }}>
        LEGAL
      </Typography>
      <Typography level="body-md" sx={{ mb: 2, color: "text.secondary" }}>
        Last modified: <strong>March 13th, 2025</strong>
      </Typography>
      <Divider sx={{ my: 2, opacity: 0.6 }} />

      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        Your use of any of Kango Studios LLC apps (the “Service”) is governed by this legal agreement (the “Terms” or "ToS"). 
        The Service is provided by Kango Studios LLC (“Kango Studios”).
      </Typography>

      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary", mt: 2 }}>
        By using the Service, you are agreeing to these Terms. Please read them carefully.
      </Typography>

      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary", mt: 2 }}>
        The Service is available to you if you are 13 years of age or older. If you are 13 or older, but under the age of 
        majority in your country of residence, you must review this agreement with your parent or guardian to make sure that 
        you and your parent or guardian understand it. You may use the Service only if you can form a binding contract with 
        Kango Studios and are not barred under any applicable laws from doing so.
      </Typography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* LEGAL NOTICE */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        LEGAL NOTICE
      </Typography>
      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        PLEASE READ THE FOLLOWING TERMS OF SERVICE AGREEMENT CAREFULLY. BY ACCESSING OR USING OUR SITES AND OUR SERVICES, 
        YOU HEREBY AGREE TO BE BOUND BY THE TERMS AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IT IS THE RESPONSIBILITY 
        OF YOU, THE USER, CUSTOMER, OR PROSPECTIVE CUSTOMER TO READ THE TERMS AND CONDITIONS BEFORE PROCEEDING TO USE THIS SITE. 
        IF YOU DO NOT EXPRESSLY AGREE TO ALL OF THE TERMS AND CONDITIONS, THEN PLEASE DO NOT ACCESS OR USE OUR SITES OR OUR SERVICES. 
        THIS TERMS OF SERVICE AGREEMENT IS EFFECTIVE AS OF 01/01/2018.
      </Typography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* ACCEPTANCE OF TERMS */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        ACCEPTANCE OF TERMS
      </Typography>
      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        The following Terms of Service Agreement (the "ToS") is a legally binding agreement that shall govern the relationship 
        with our users and others which may interact or interface with Kango Studios LLC, Kango Studios, also known as Kango, and our subsidiaries and affiliates, in association with the use of the Kango website, which includes 
        KangoStudios.com, www.KangoStudios.com, (the "Site") and its Services, which shall be defined below.
      </Typography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* REMAINING CONTENT */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
      DESCRIPTION OF WEBSITE SERVICES OFFERED
      </Typography>

      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary", mt: 2 }}>
        The Site is a mobile development website which has the following description: To provide customers a feedback form, a look into what we have built and what we are building next. Any and all visitors to our site shall be deemed as "users" of the herein contained Services provided for the purpose of this ToS.
        </Typography>

        <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary", mt: 2 }}>
        The user acknowledges and agrees that the Services provided and made available through our website and applications, which may include some mobile applications and that those applications may be made available on various social media networking sites and numerous other platforms and downloadable programs, are the sole property of Kango Studios. At its discretion, Kango Studios may offer additional website Services and/or products, or update, modify or revise any current content and Services, and this Agreement shall apply to any and all additional Services and/or products and any and all updated, modified or revised Services unless otherwise stipulated. Kango Studios does hereby reserve the right to cancel and cease offering any of the aforementioned Services and/or products. You, as the end user acknowledge, accept and agree that Kango Studios shall not be held liable for any such updates, modifications, revisions, suspensions or discontinuance of any of our Services and/or products. Your continued use of the Services provided, after such posting of any updates, changes, and/or modifications shall constitute your acceptance of such updates, changes and/or modifications, and as such, frequent review of this Agreement and any and all applicable terms and policies should be made by you to ensure you are aware of all terms and policies currently in effect. Should you not agree to the updated, revised or modified terms, you must stop using the provided Services forthwith.

      </Typography>

      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary", mt: 2 }}>
        Furthermore, the user understands, acknowledges and agrees that the Services offered shall be provided "AS IS" and as such Kango Studios shall not assume any responsibility or obligation for the timeliness, missed delivery, deletion and/or any failure to store user content, communication or personalization settings.
      </Typography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

{/* PRIVACY POLICY */}
<Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        PRIVACY POLICY
      </Typography>
      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        Every member's registration data and various other personal information are strictly protected 
        by the Kango Studios Online Privacy Policy (
        <Link href="/privacy" sx={{ fontWeight: "bold" }}>see the full Privacy Policy</Link>). 
        As a member, you herein consent to the collection and use of the information provided, including 
        the transfer of information within the United States and/or other countries for storage, processing 
        or use by Kango Studios and/or our subsidiaries and affiliates.
      </Typography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* GLOBAL USE & EXPORT COMPLIANCE */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        CAUTIONS FOR GLOBAL USE AND EXPORT AND IMPORT COMPLIANCE
      </Typography>
      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        Due to the global nature of the internet, through the use of our network you hereby agree to comply 
        with all local rules relating to online conduct and that which is considered acceptable Content. 
        Uploading, posting, and/or transferring of software, technology, and other technical data may be subject 
        to the export and import laws of the United States and possibly other countries. Through the use of our 
        network, you thus agree to comply with all applicable export and import laws, statutes, and regulations, 
        including, but not limited to, the Export Administration Regulations (Export Administration Regulations), 
        as well as the sanctions control program of the United States (sanctions control program). Furthermore, 
        you state and pledge that you:
      </Typography>

      <Typography level="body-md" sx={{ mt: 2, color: "text.secondary" }}>
        a) Are not on the list of prohibited individuals which may be identified on any government export exclusion 
        report nor a member of any other government which may be part of an export-prohibited country identified in 
        applicable export and import laws and regulations;
      </Typography>
      <Typography level="body-md" sx={{ mt: 1, color: "text.secondary" }}>
        b) Agree not to transfer any software, technology, or any other technical data through the use of our network 
        Services to any export-prohibited country;
      </Typography>
      <Typography level="body-md" sx={{ mt: 1, color: "text.secondary" }}>
        c) Agree not to use our website network Services for any military, nuclear, missile, chemical, or biological 
        weaponry end uses that would be a violation of the U.S. export laws; and
      </Typography>
      <Typography level="body-md" sx={{ mt: 1, color: "text.secondary" }}>
        d) Agree not to post, transfer, nor upload any software, technology, or any other technical data which would be 
        in violation of the U.S. or other applicable export and/or import laws.
      </Typography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* CONTENT PLACEMENT & LICENSES */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        CONTENT PLACED OR MADE AVAILABLE FOR COMPANY SERVICES
      </Typography>
      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        Kango Studios shall not lay claim to ownership of any content submitted by any visitor or user, nor make such 
        content available for inclusion on our website Services. Therefore, you hereby grant and allow for Kango Studios 
        the below-listed worldwide, royalty-free, and non-exclusive licenses, as applicable:
      </Typography>

      <Typography level="body-md" sx={{ mt: 2, color: "text.secondary" }}>
        a) The content submitted or made available for inclusion on the publicly accessible areas of Kango Studios's 
        sites, the license provided to permit to use, distribute, reproduce, modify, adapt, publicly perform and/or 
        publicly display said content on our network Services is for the sole purpose of providing and promoting the 
        specific area to which this content was placed and/or made available for viewing. This license shall be available 
        so long as you are a member of Kango Studios's sites and shall terminate at such time when you elect to discontinue 
        your membership.
      </Typography>
      <Typography level="body-md" sx={{ mt: 1, color: "text.secondary" }}>
        b) Photos, audio, video, and/or graphics submitted or made available for inclusion on the publicly accessible 
        areas of Kango Studios's sites, the license provided to permit to use, distribute, reproduce, modify, adapt, 
        publicly perform and/or publicly display said content on our network Services are for the sole purpose of 
        providing and promoting the specific area in which this content was placed and/or made available for viewing. 
        This license shall be available so long as you are a member of Kango Studios's sites and shall terminate at such 
        time when you elect to discontinue your membership.
      </Typography>
      <Typography level="body-md" sx={{ mt: 1, color: "text.secondary" }}>
        c) For any other content submitted or made available for inclusion on the publicly accessible areas of Kango 
        Studios's sites, the continuous, binding, and completely sub-licensable license which is meant to permit to use, 
        distribute, reproduce, modify, adapt, publish, translate, publicly perform and/or publicly display said content, 
        whether in whole or in part, and the incorporation of any such content into other works in any arrangement or 
        medium currently used or later developed.
      </Typography>

      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary", mt: 3 }}>
        Those areas which may be deemed "publicly accessible" areas of Kango Studios's sites are those such areas of our 
        network properties which are meant to be available to the general public, and which would include message boards 
        and groups that are openly available to users.
      </Typography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

{/* CONTRIBUTIONS TO COMPANY WEBSITE */}
<Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        CONTRIBUTIONS TO COMPANY WEBSITE
      </Typography>
      <Typography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        Kango Studios provides an area for our users to contribute feedback to our website. When you submit 
        ideas, documents, suggestions, and/or proposals ("Contributions") to our site, you acknowledge and 
        agree that:
      </Typography>

      <Typography component="ul" sx={{ mt: 2, pl: 3, color: "text.secondary" }}>
        <li>Your contributions do not contain any type of confidential or proprietary information;</li>
        <li>Kango shall not be liable or under any obligation to ensure or maintain confidentiality, expressed or implied, related to any Contributions;</li>
        <li>Kango shall be entitled to make use of and/or disclose any such Contributions in any such manner as they may see fit;</li>
        <li>The contributor's Contributions shall automatically become the sole property of Kango;</li>
        <li>Kango is under no obligation to either compensate or provide any form of reimbursement in any manner or nature.</li>
      </Typography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* INDEMNITY */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        INDEMNITY
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        All users herein agree to **insure and hold Kango Studios, our subsidiaries, affiliates, agents, employees, 
        officers, partners, and/or licensors blameless** or not liable for any claim or demand, which may include, 
        but is not limited to, **reasonable attorney fees** made by any third party which may arise from:
      </MarkdownTypography>

      <Typography component="ul" sx={{ mt: 2, pl: 3, color: "text.secondary" }}>
        <li>Any content a user submits, posts, modifies, transmits, or makes available through our Services;</li>
        <li>The use of Kango Studios Services or your connection with these Services;</li>
        <li>Your violations of the Terms of Service;</li>
        <li>Your violation of any rights of another person.</li>
      </Typography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* COMMERCIAL REUSE OF SERVICES */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        COMMERCIAL REUSE OF SERVICES
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        The user herein **agrees not to replicate, duplicate, copy, trade, sell, resell, nor exploit** for any 
        commercial reason any part, use of, or access to **Kango Studios' sites and apps**.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* MODIFICATIONS */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        MODIFICATIONS
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        Kango Studios shall reserve the right at any time it may deem fit, to **modify, alter, and/or discontinue, 
        whether temporarily or permanently**, our service, or any part thereof, with or without prior notice. 
      </MarkdownTypography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 2, color: "text.secondary" }}>
        In addition, **Kango Studios shall not be held liable** to you or to any third party for any such 
        **alteration, modification, suspension, and/or discontinuance** of our Services, or any part thereof.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* ADVERTISERS */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        ADVERTISERS
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        Any correspondence or business dealings with, or the participation in any **promotions of, advertisers** 
        located on or through our Services, which may include the **payment and/or delivery of goods and/or 
        Services**, and any such other terms, conditions, warranties, and/or representations associated with such 
        dealings, are and shall be **solely between you and any such advertiser**.
      </MarkdownTypography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 2, color: "text.secondary" }}>
        Moreover, you **herein agree that Kango Studios shall not be held responsible or liable** for any loss or 
        damage of any nature or manner incurred as a direct result of any such dealings or as a result of the 
        **presence of such advertisers** on our website.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* LINKS */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        LINKS
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        Either Kango Studios or any **third parties** may provide links to **other websites and/or resources**. 
        Thus, you acknowledge and agree that we are **not responsible for the availability of any such external 
        sites or resources**, and as such, we do not **endorse nor are we responsible or liable** for any content, 
        products, advertising, or any other materials, on or available from such third-party sites or resources.
      </MarkdownTypography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 2, color: "text.secondary" }}>
        Furthermore, you acknowledge and agree that **Kango Studios shall not be responsible or liable**, directly 
        or indirectly, for any such **damage or loss** which may be a result of, caused, or allegedly to be caused 
        by or in connection with the **use of or reliance on any such content, goods, or Services** made available 
        on or through any such site or resource.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />


      {/* PROPRIETARY RIGHTS */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        PROPRIETARY RIGHTS
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        You do hereby acknowledge and agree that **Kango Studios's Services** and any essential software 
        that may be used in connection with our Services ("Software") shall contain **proprietary and confidential 
        material** that is protected by applicable **intellectual property rights and other laws**.
      </MarkdownTypography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 2, color: "text.secondary" }}>
        Furthermore, you herein acknowledge and agree that any Content which may be contained in **advertisements** 
        or information presented **by and through our Services or by advertisers** is protected by **copyrights, 
        trademarks, patents, or other proprietary rights and laws**. Therefore, except for that which is **expressly 
        permitted** by applicable law or as authorized by Kango Studios or such applicable licensor, you agree **not 
        to alter, modify, lease, rent, loan, sell, distribute, transmit, broadcast, publicly perform, and/or create 
        any plagiaristic works** which are based on Kango Studios Services (e.g., Content or Software), in whole or part.
      </MarkdownTypography>

      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 2, color: "text.secondary" }}>
        Kango Studios herein has granted you a **personal, non-transferable, and non-exclusive right and/or license** 
        to make use of the object code of our Software on a single computer, as long as you do not, and shall not:
      </MarkdownTypography>
      <Typography component="ul" sx={{ mt: 2, pl: 3, color: "text.secondary" }}>
        <li>Allow any third party to duplicate, alter, modify, create, or plagiarize work from our Software.</li>
        <li>Reverse engineer, reverse assemble, or otherwise attempt to locate or discern any source code.</li>
        <li>Sell, assign, sublicense, grant a security interest in, and/or otherwise transfer any such right in the Software.</li>
        <li>Alter or change the Software in any manner, nature, or form.</li>
        <li>Use any modified versions of the Software, including, but not limited to, for unauthorized access to our Services.</li>
        <li>Access or attempt to access our Services through any means other than through the interface provided by Kango Studios.</li>
      </Typography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* WARRANTY DISCLAIMERS */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        WARRANTY DISCLAIMERS
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        YOU HEREIN **EXPRESSLY ACKNOWLEDGE AND AGREE** THAT:
      </MarkdownTypography>

      <Typography component="ul" sx={{ mt: 2, pl: 3, color: "text.secondary" }}>
        <li>
          THE USE OF KANGO STUDIOS SERVICES AND SOFTWARE ARE **AT YOUR SOLE RISK**. OUR SERVICES AND SOFTWARE 
          ARE PROVIDED **"AS IS" AND/OR "AS AVAILABLE"**.
        </li>
        <li>
          KANGO STUDIOS AND OUR SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, AND LICENSORS 
          **DISCLAIM ALL WARRANTIES**, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES 
          OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
        </li>
        <li>
          KANGO STUDIOS MAKES **NO WARRANTIES** THAT:  
          <ul style={{ marginLeft: "20px" }}>
            <li>(i) OUR SERVICES OR SOFTWARE WILL MEET YOUR REQUIREMENTS.</li>
            <li>(ii) OUR SERVICES OR SOFTWARE SHALL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.</li>
            <li>(iii) RESULTS FROM THE USE OF OUR SERVICES OR SOFTWARE WILL BE ACCURATE OR RELIABLE.</li>
            <li>(iv) THE QUALITY OF PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED THROUGH OUR SERVICES WILL MEET YOUR EXPECTATIONS.</li>
            <li>(v) ANY ERRORS IN THE SOFTWARE SHALL BE CORRECTED.</li>
          </ul>
        </li>
        <li>
          ANY INFORMATION OR MATERIAL DOWNLOADED OR OTHERWISE OBTAINED FROM KANGO STUDIOS SERVICES OR SOFTWARE 
          IS **AT YOUR SOLE DISCRETION AND RISK**. YOU SHALL BE **SOLELY RESPONSIBLE** FOR ANY DAMAGE TO YOUR 
          COMPUTER, INTERNET ACCESS, OR LOSS OF DATA FROM DOWNLOADING SUCH MATERIAL.
        </li>
        <li>
          NO ADVICE AND/OR INFORMATION, WHETHER WRITTEN OR ORAL, OBTAINED FROM KANGO STUDIOS OR OUR SERVICES 
          SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS OF SERVICE.
        </li>
      </Typography>

      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 3, color: "text.secondary" }}>
        A small percentage of users may experience **some degree of epileptic seizure** when exposed to 
        certain light patterns or backgrounds. If you, or anyone you know, has a history of epilepsy, 
        consult a physician **if you experience symptoms such as dizziness, vision changes, muscle twitches, 
        loss of awareness, disorientation, or convulsions** while using our Services.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* LIMITATION OF LIABILITY */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        LIMITATION OF LIABILITY
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        YOU **EXPLICITLY ACKNOWLEDGE, UNDERSTAND, AND AGREE** THAT KANGO STUDIOS AND OUR SUBSIDIARIES, 
        AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR 
        **ANY PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES**, INCLUDING, 
        BUT NOT LIMITED TO:
      </MarkdownTypography>

      <Typography component="ul" sx={{ mt: 2, pl: 3, color: "text.secondary" }}>
        <li>LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES.</li>
        <li>THE USE OR INABILITY TO USE OUR SERVICE.</li>
        <li>THE COST OF PROCURING SUBSTITUTE GOODS AND SERVICES.</li>
        <li>UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA.</li>
        <li>STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON OUR SERVICE.</li>
        <li>ANY OTHER MATTER RELATED TO OUR SERVICE.</li>
      </Typography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* RELEASE */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        RELEASE
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        In the event of a dispute, you agree to **release Kango Studios (and its officers, directors, employees, 
        agents, parent subsidiaries, affiliates, co-branders, partners, and any other third parties) from claims, 
        demands, and damages (actual and consequential) of every kind and nature, known and unknown, suspected or 
        unsuspected, disclosed and undisclosed, arising out of or in any way connected to such dispute**.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

{/* AUTO-RENEWING SUBSCRIPTIONS */}
<Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        AUTO-RENEWING SUBSCRIPTIONS
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        **Recurring billing, cancel anytime.** If you choose to purchase a subscription, payment will be 
        charged to your **iTunes account**, and your account will be charged **within 24 hours prior** 
        to the end of the current period.
      </MarkdownTypography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 2, color: "text.secondary" }}>
        Auto-renewal may be turned off at any time by going to your **settings in the iTunes store** 
        after purchase. For more information, please visit our **Terms of Service and Privacy Policy**. 
        Any unused portion of a free trial period, if offered, will be **forfeited when the user purchases 
        a subscription** to that publication, where applicable.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* SPECIAL ADMONITION RELATED TO FINANCIAL MATTERS */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        SPECIAL ADMONITION RELATED TO FINANCIAL MATTERS
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        If you intend to **create or join any service, receive or request financial news, messages, alerts, 
        or other investment-related information** from our Services, please **review the Warranty Disclaimers 
        and Limitations of Liability** sections carefully.
      </MarkdownTypography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 2, color: "text.secondary" }}>
        In addition, for this type of information, the phrase **"Let the investor beware"** is appropriate.  
        Kango Studios provides content **primarily for informational purposes**. No content provided or 
        included in our Services is **intended for trading or investing purposes**. Kango Studios and our 
        licensors **shall not be responsible or liable** for the **accuracy, usefulness, or availability** 
        of any information transmitted through our Services.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* EXCLUSION AND LIMITATIONS */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        EXCLUSION AND LIMITATIONS
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        Some jurisdictions **do not allow** the exclusion of certain warranties or the **limitation of 
        liability for incidental or consequential damages**. Therefore, some of the **above limitations 
        in Warranty Disclaimers and Limitation of Liability may not apply to you**.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* THIRD PARTY BENEFICIARIES */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        THIRD PARTY BENEFICIARIES
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        You acknowledge, understand, and agree that, unless otherwise expressly stated in these 
        **Terms of Service**, there shall be **no third-party beneficiaries** to this agreement.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* NOTICE */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        NOTICE
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        Kango Studios may furnish you with **notices**, including changes to these Terms of Service, via 
        email, regular mail, **MMS or SMS**, text messaging, postings on our website, or other reasonable 
        means currently known or developed in the future.
      </MarkdownTypography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 2, color: "text.secondary" }}>
        Any such notices may **not be received** if you violate the Terms of Service by accessing our 
        Services in an **unauthorized manner**. Your **acceptance of these Terms** constitutes your 
        agreement that you are **deemed to have received** any notices that would have been delivered 
        had you accessed our Services **legally**.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* TRADEMARK INFORMATION */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        TRADEMARK INFORMATION
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        You acknowledge, understand, and agree that **all Kango Studios trademarks, copyrights, trade names, 
        service marks, logos, brand features, and product and service names** are **the sole property of 
        Kango Studios**.
      </MarkdownTypography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 2, color: "text.secondary" }}>
        You **agree not to display or use** in any manner the **Kango Studios logo or marks** without 
        **prior written consent** from Kango Studios.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* COPYRIGHT OR INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS NOTICE & PROCEDURES */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        COPYRIGHT OR INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS NOTICE & PROCEDURES
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        Kango Studios respects the **intellectual property rights** of others and asks that our users 
        do the same. Under appropriate circumstances, Kango Studios may **disable or terminate** the 
        accounts of users who violate these **Terms of Service** or infringe on the **rights of others**.
      </MarkdownTypography>

      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 2, color: "text.secondary" }}>
        If you believe that your work has been duplicated **in a way that constitutes copyright 
        infringement**, or if your **intellectual property rights have been violated**, you should 
        provide us with the following information:
      </MarkdownTypography>

      <Typography component="ul" sx={{ mt: 2, pl: 3, color: "text.secondary" }}>
        <li>The electronic or physical signature of the individual authorized on behalf of the copyright or intellectual property owner.</li>
        <li>A description of the copyrighted work or intellectual property that you claim has been infringed.</li>
        <li>A description of the location of the infringing content on our website.</li>
        <li>Your physical address, telephone number, and email address.</li>
        <li>A statement declaring that the use of your work is unauthorized by the copyright owner, its agents, or the law.</li>
        <li>A statement, made under **penalty of perjury**, that the information provided is truthful and that you are the copyright or intellectual property owner (or their authorized agent).</li>
      </Typography>

      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 3, color: "text.secondary" }}>
        The **Kango Studios Agent** for copyright or intellectual property infringement claims **can be contacted as follows:**
      </MarkdownTypography>
      <Link href="mailto:hello@kangostudios.com" sx={{ color: "primary.main" }}>
        hello@kangostudios.com
      </Link>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

{/* ENTIRE AGREEMENT */}
<Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        ENTIRE AGREEMENT
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        This **Terms of Service (ToS)** constitutes the **entire agreement** between you and **Kango Studios**, 
        governing your use of our Services. It **supersedes any prior agreements** between you and Kango Studios 
        regarding the Services. You may also be subject to **additional terms and conditions** when using or purchasing 
        certain Kango Studios **Services, affiliate services, third-party content, or software**.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* CHOICE OF LAW AND FORUM */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        CHOICE OF LAW AND FORUM
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        It is mutually agreed that **this ToS** and the relationship between you and **Kango Studios** shall be 
        **governed by the laws of the state of Maryland**, without regard to its **conflict of law provisions**.
      </MarkdownTypography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 2, color: "text.secondary" }}>
        Any claims, disputes, or causes of action arising from the **ToS or your relationship with Kango Studios** 
        must be filed within the courts having jurisdiction within **Howard County, Maryland**, or the **U.S. District 
        Court located in Maryland**.
      </MarkdownTypography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 2, color: "text.secondary" }}>
        By using our Services, **you and Kango Studios agree to submit to the jurisdiction of these courts** and 
        waive any objections to the **exercise of jurisdiction or venue** in such courts.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* WAIVER AND SEVERABILITY OF TERMS */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        WAIVER AND SEVERABILITY OF TERMS
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        If at any time **Kango Studios** fails to **enforce any right or provision** of these **Terms of Service**, 
        such failure **does not constitute a waiver** of that right or provision.
      </MarkdownTypography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", mt: 2, color: "text.secondary" }}>
        If any provision of this **ToS is found to be invalid by a court of competent jurisdiction**, the court should 
        attempt to give effect to the **intentions of the parties**, and the **remaining provisions** shall continue 
        in full force and effect.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* STATUTE OF LIMITATIONS */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        STATUTE OF LIMITATIONS
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        You acknowledge and agree that, **regardless of any statute or law to the contrary**, any **claim or action** 
        arising out of or related to the **use of our Services or this ToS** must be **filed within one (1) year** 
        after the claim or cause of action arose. **Failure to do so shall result in the claim being permanently barred**.
      </MarkdownTypography>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* VIOLATIONS */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "error.700", mb: 1 }}>
        VIOLATIONS
      </Typography>
      <MarkdownTypography level="body-md" sx={{ textAlign: "justify", color: "text.secondary" }}>
        Please report any and all **violations** of these **Terms of Service** to **Kango Studios** at the following contact:
      </MarkdownTypography>

      <Link href="mailto:hello@kangostudios.com" sx={{ color: "primary.main" }}>
        hello@kangostudios.com
      </Link>

      <Divider sx={{ my: 3, opacity: 0.6 }} />

      {/* CONTACT INFO */}
      <Typography level="h4" sx={{ fontWeight: "bold", color: "text.primary", mb: 1 }}>
        CONTACT US
      </Typography>
      <Typography level="body-md" sx={{ opacity: 0.9 }}>
        If you have any questions regarding our Terms of Service or need further clarification, please contact us at:
      </Typography>
      <Link href="mailto:hello@kangostudios.com" sx={{ fontWeight: "bold", fontSize: "1.1rem", mt: 1, display: "block" }}>
        hello@kangostudios.com
      </Link>

      <Typography level="body-sm" sx={{ opacity: 0.7, mt: 3, textAlign: "justify" }}>
        Our Terms of Service may be updated from time to time. Any changes will be posted on this page.
      </Typography>
    </Container>
    </Sheet>
  );
}
