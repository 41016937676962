import React from "react";
import ReactMarkdown from "react-markdown";
import { Typography, Link } from "@mui/joy";

export default function MarkdownTypography({ children, level = "body-md", sx = {} }) {
  return (
    <Typography level={level} sx={sx}>
      <ReactMarkdown
        components={{
          strong: ({ node, ...props }) => <Typography component="strong" sx={{ fontWeight: "bold" }} {...props} />,
          em: ({ node, ...props }) => <Typography component="em" sx={{ fontStyle: "italic" }} {...props} />,
          a: ({ node, ...props }) => (
            <Link sx={{ color: "primary.main", "&:hover": { color: "secondary.main" } }} {...props} />
          ),
        }}
      >
        {children}
      </ReactMarkdown>
    </Typography>
  );
}
