import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom"; // ✅ Correct import
import { Box } from "@mui/joy";
import { CssVarsProvider } from "@mui/joy";
import { ThemeProvider } from "@mui/material/styles";
import CustomTheme from "./theme/theme"; // 👈 Import the theme

import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Legal from "./pages/Legal";
import NotFound from "./pages/NotFound"; // your custom 404 page


export default function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    document.body.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
          <ThemeProvider theme={CustomTheme}>
          <CssVarsProvider defaultMode="light">

      {/* Navbar */}
      <Navbar 
        onDrawerToggle={() => setMobileOpen(true)} 
        theme={theme} 
        toggleTheme={() => setTheme(theme === "light" ? "dark" : "light")} 
      />

      {/* Sidebar (Mobile & Desktop) */}
      <Sidebar 
        mobileOpen={mobileOpen} 
        onClose={() => setMobileOpen(false)} 
        theme={theme} 
        toggleTheme={() => setTheme(theme === "light" ? "dark" : "light")} 
      />

      {/* Main Content */}
      <Box>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/legal" element={<Legal />} />

            {/* Catch-all 404 route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
      </Box>
      <Footer />
      </CssVarsProvider>
      </ThemeProvider>
  );
}
