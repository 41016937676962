import React from "react";
import { Sheet, Typography, Container, Stack, IconButton, Button } from "@mui/joy";
import { Link, useLocation } from "react-router-dom";
import { useColorScheme } from "@mui/joy";

import MenuIcon from "@mui/icons-material/Menu";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { menuItems } from "./MenuItems"; // ✅ Import shared menu items

function NavbarContent({ onDrawerToggle }) {
    const { mode, setMode } = useColorScheme(); // Hook for MUI Joy theme
    const location = useLocation(); // ✅ Get the current URL path

    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {/* Left Section (Menu Button + Logo) */}
        <Stack direction="row" alignItems="center" spacing={1}>
          {/* Mobile Drawer Button (Shown only on small screens) */}
          <IconButton onClick={onDrawerToggle} sx={{ display: { xs: "flex", sm: "none" } }}>
            <MenuIcon />
          </IconButton>
  
          {/* Logo + Company Name */}
          <Link to="/" style={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" style={{ height: "50px" }} />
          <Typography level="h4" sx={{ fontWeight: "bold", color: "primary.main", marginLeft: 1 }}>
              Kango Studios
            </Typography>
          </Link>
        </Stack>
  
        {/* Desktop Navigation Links */}
        <Stack direction="row" spacing={3} sx={{ display: { xs: "none", sm: "flex" } }}>
          {menuItems.map((item) => {
            const isActive = location.pathname === item.path; // ✅ Check if current page matches item

          return (
            <Button
              key={item.label}
              component={Link}
              to={item.path}
              variant="plain"
              sx={{
                fontWeight: "bold",
                color: isActive ? "#05A9F4" : "text.primary", // ✅ Highlight active item
                position: "relative",
                "&:hover": { color: "primary.main", transform: "scale(1.1)" },
                "&::after": isActive
                  ? {
                      content: '""',
                      position: "absolute",
                      bottom: -2,
                      left: 0,
                      width: "100%",
                      height: 2,
                      backgroundColor: "primary.main",
                      transition: "width 0.3s ease-in-out",
                    }
                  : {},
                transition: "0.3s",
              }}
            >
              {item.label}
            </Button>
          );
        })}
        </Stack>
  
        {/* Right Section (Light/Dark Mode Toggle) */}
        <IconButton onClick={() => setMode(mode === "light" ? "dark" : "light")}>
          {mode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
        </IconButton>
      </Stack>
    );
  }
  
  export default function Navbar({ onDrawerToggle }) {
    return (
      <Sheet
        variant="solid"
        color="neutral"
        sx={{
          py: 1.5,
          boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "background.surface", // ✅ Uses MUI theme background
          color: "text.primary", // ✅ Uses MUI theme text color
          transition: "background 0.3s ease-in-out",
        }}
      >
        <Container>
          <NavbarContent onDrawerToggle={onDrawerToggle} />
        </Container>
      </Sheet>
    );
  }