// src/pages/Portfolio.jsx
import React from "react";
import { Sheet, Typography, Container, Grid, Card, CardContent, Button } from "@mui/joy";

function LatestWork() {
    const projects = [
      {
        name: "Subscription Manager",
        description: "Keep track of your subscriptions and services the easy way!",
        image: `${process.env.PUBLIC_URL}/assets/subscription-manager-preview.png`,
        link: "https://itunes.apple.com/us/app/subscription-manager-bills/id1259029889?mt=8"
      },
      {
        name: "Grade Control",
        description: "Track grades, GPA, and know what grades you need!",
        image: `${process.env.PUBLIC_URL}/assets/grade-control-preview.png`,
        link: "https://itunes.apple.com/us/app/grade-control-school-grades/id1123787414?mt=8"
      },
      {
        name: "Flow",
        description: "Budget the easy way! Track account balances, not transactions!",
        image: `${process.env.PUBLIC_URL}/assets/flow-preview.png`,
        link: "https://itunes.apple.com/us/app/loot-track-your-money/id1447322358?mt=8"
      },
      {
        name: "Lifting Log",
        description: "Track workouts, exercises, measurements, and more...",
        image: `${process.env.PUBLIC_URL}/assets/lifting-log-preview.png`,
        link: "https://apps.apple.com/us/app/id1486426330"
      }
    ];
  
    return (
      <Container>
        <Typography level="h3" align="center" gutterBottom sx={{ color: "text.primary", mb: 4 }}>
          Portfolio
        </Typography>
        <Grid container spacing={2}>
          {projects.map((project) => (
            <Grid item xs={12} sm={6} md={3} key={project.name}>
              <Card variant="outlined" sx={{ textAlign: "center", overflow: "hidden", alignItems: "center" }}>
                <img
                  src={project.image}
                  alt={project.name}
                  style={{ width: "150px", height: "150px", objectFit: "cover" }}
                />
                <CardContent>
                  <Typography level="h5">{project.name}</Typography>
                  <Typography level="body-sm" sx={{ mt: 1, color: "text.secondary" }}>
                    {project.description}
                  </Typography>
                  {project.link && (
                    <Button component="a" href={project.link} variant="soft" sx={{ mt: 2 }}>
                      App Store
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Typography level="h4" sx={{ maxWidth: 700, textAlign: "center", mx: "auto", color: "text.secondary", mt: 6 }}>
        We can't wait to show you what we have in store next...
          </Typography>

      </Container>
    );
}

function Content() {
  return (
    <Sheet
    sx={{
      minHeight: "100vh", // ✅ Ensures background covers entire viewport
      backgroundColor: "background.surface", // ✅ Uses theme background
      color: "text.primary", // ✅ Ensures text color adapts to theme
      display: "flex",
      flexDirection: "column", // ✅ Fixes spacing issues
      alignItems: "center",
      justifyContent: "flex-start", // ✅ Prevents extra space at the top
      py: 6,
    }}
  >
  <LatestWork></LatestWork>
  </Sheet>
  );
}



export default function Portfolio() {
    return (
        <>
        <Content />
        </>
    )
}