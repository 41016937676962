import { createTheme } from "@mui/material/styles"; // 👈 Import this!

const theme = createTheme({
    palette: {
      primary: {
        main: "#05A9F4",  // Change primary to orange
      },
      secondary: {
        main: "#00E9FA",  // Define a secondary color
      }
    },
  });
  
  export default theme;