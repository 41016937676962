import React from 'react';
import { Sheet, Typography, Divider, Link, Container, Button } from "@mui/joy";
import { Link as RouterLink } from 'react-router-dom'; // Make sure to use this for `to="/"`

export default function NotFound() {
  return (
    <Sheet
      sx={{
        minHeight: "100dvh",
        backgroundColor: "background.surface",
        color: "text.primary",
        display: "flex",
        flexDirection: "column", // ✅ Fixes spacing issues
        alignItems: "center",
        justifyContent: "flex-start", // ✅ Prevents extra space at the top
        px: 2,
        pt: 12,
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          textAlign: "center",
          py: 6, // ✅ Less vertical padding
          animation: "fadeIn 0.6s ease-out",
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(20px)' },
            to: { opacity: 1, transform: 'translateY(0)' },
          },
        }}
      >
        <Typography level="h1" sx={{ fontSize: "6rem", fontWeight: "bold", color: "primary.main", mb: 2 }}>
          404
        </Typography>

        <Typography level="h3" sx={{ fontWeight: "bold", mb: 1 }}>
          Uh-oh! Page not found 😢
        </Typography>

        <Typography level="body-lg" sx={{ maxWidth: 500, mx: "auto", mb: 3, opacity: 0.8 }}>
          The page you're looking for doesn't exist or has been moved. Let's get you back on track.
        </Typography>

        <Divider sx={{ my: 4, opacity: 0.4 }} />

        <Link component={RouterLink} to="/" underline="none">
          <Button
            size="lg"
            sx={{
              px: 4,
              py: 1.5,
              fontSize: "1.1rem",
              fontWeight: "bold",
              borderRadius: "99px",
              textTransform: "none",
              boxShadow: "lg",
            }}
            variant="solid"
            color="primary"
          >
            Return to Homepage
          </Button>
        </Link>
      </Container>
    </Sheet>
  );
}
